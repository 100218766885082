<template>
  <base-material-card icon="mdi-account" :title="$t(title)">
    <v-card class="px-4">
      <customers-form
        v-if="customer"
        :title="$t('new', { name: $tc('customer') })"
        :customer="customer"
        @validated="updateCustomer"
        :view="true"
        ref="customerForm"
        :skip_validate="true"
        ><template>
          <v-col
            cols="12"
            md="3"
            class="py-0"
            v-if="$store.getters['auth/isStudio']"
          >
            <label v-text="$t('customers.contact')" />
            <v-select
              dense
              outlined
              hide-details
              v-model="customer.contact"
              class="inputs"
              single-line
              :items="tattooers"
              item-value="id"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.user.first_name }}
                {{ data.item.user.last_name }}
              </template>
            </v-select>
          </v-col>
        </template></customers-form
      >
    </v-card>
  </base-material-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CustomerEdit",
  components: { CustomersForm: () => import("@/components/customer/Form") },
  mounted() {
    this.fetchCustomer();
    this.fetchActiveTattooer();
  },
  data() {
    return {
      customerSaved: false,
      validationErrors: [],
      customer: { user: { phone: null } },
      tattooers: [],
    };
  },
  methods: {
    ...mapActions("customers", ["updateCustomerWithoutDate", "getCustomer"]),
    fetchCustomer() {
      this.getCustomer(this.$route.params.id).then((response) => {
        this.customer = response;
        console.log(this.customer);
        if (
          this.customer.relations.length >= 1 &&
          this.customer.relations[0].from == "tattooer"
        ) {
          this.customer.contact = this.customer.relations[0].tattooer.user.id;
        }
        if (this.customer.height !== null)
          this.customer.height = Math.round(this.customer.height * 100) / 100;
        if (this.customer.weight !== null)
          this.customer.weight = Math.round(this.customer.weight * 10) / 10;
        this.customer.user.phone = null;
        this.$refs.custtomerForm.customerAllergies();
      });
    },
    updateCustomer() {
      //console.log("update");
      console.log(this.customer.city);
      if (typeof this.customer.city == "object" && this.customer.city != null)
        this.customer.city = this.customer.city.nm;
      this.updateCustomerWithoutDate({
        customer_id: this.$route.params.id,
        customer: this.customer,
      }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("customer_edit_success"), "", "");
        }
        this.$router.back();
      });
    },
    ...mapActions("studios", ["getActiveTattooer"]),
    fetchActiveTattooer() {
      this.getActiveTattooer().then((data) => {
        this.tattooers = data.map((x) => x.tattooer);
        let user = this.$store.state.auth.user;
        this.tattooers.push({
          id: user.id,
          user: { first_name: user.studio.studio_name },
        });
      });
    },
  },
};
</script>
